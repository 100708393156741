var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "log-container" },
    [
      _c("div", { staticClass: "log-title" }, [
        _c("span", [_vm._v(_vm._s(_vm.$t("lang_flow_log_process_opinion")))]),
        _vm._processEnd
          ? _c("span", { staticClass: "process-end" }, [
              _vm._v("(" + _vm._s(_vm.$t("lang_ended")) + ")"),
            ])
          : _vm._e(),
      ]),
      _c(
        "el-timeline",
        { staticClass: "log-content" },
        _vm._l(_vm.logs, function (nodeItem) {
          return _c(
            "el-timeline-item",
            {
              key: nodeItem.nodeId,
              staticClass: "log-item-wrapper",
              attrs: {
                size: "large",
                color: nodeItem.color,
                id: nodeItem.nodeId,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "log-item-title",
                  style: {
                    color: nodeItem.color,
                    fontWeight: nodeItem.fontWeight,
                  },
                },
                [_vm._v(" " + _vm._s(nodeItem.displayNodeName) + " ")]
              ),
              nodeItem.runtimeId != _vm._currentTask.runtimeId
                ? _c(
                    "div",
                    {
                      staticClass: "log-item-tip",
                      on: {
                        click: function ($event) {
                          return _vm.openHistoryTask(nodeItem)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(nodeItem.nodeTip) + " ")]
                  )
                : _vm._e(),
              _vm._l(nodeItem.taskHistories, function (logItem) {
                return _c(
                  "div",
                  {
                    key: logItem.id,
                    staticClass: "log-item-content",
                    style: {
                      color: nodeItem.fontColor,
                      fontWeight: nodeItem.fontWeight,
                      borderColor: nodeItem.color,
                    },
                  },
                  [
                    logItem.completed
                      ? [
                          _c("div", { staticClass: "log-item-row" }, [
                            _c("div", { staticClass: "log-item-row-label" }, [
                              _vm._v(
                                _vm._s(_vm.$t("lang_flow_log_handler")) + "："
                              ),
                            ]),
                            _c("div", { staticClass: "log-item-row-value" }, [
                              _vm._v(_vm._s(logItem.completedUserName)),
                            ]),
                          ]),
                          _c("div", { staticClass: "log-item-row" }, [
                            _c("div", { staticClass: "log-item-row-label" }, [
                              _vm._v(
                                _vm._s(_vm.$t("lang_flow_log_process_result")) +
                                  "："
                              ),
                            ]),
                            _c("div", { staticClass: "log-item-row-value" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "log-item-row-value-type",
                                  style: {
                                    backgroundColor: nodeItem.color,
                                    color: "#fff",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.getSubmitTypeName(logItem)) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                          logItem.userRemark
                            ? _c("div", { staticClass: "log-item-row" }, [
                                _c(
                                  "div",
                                  { staticClass: "log-item-row-label" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "lang_flow_log_handling_comments"
                                        )
                                      ) + "："
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "log-item-row-value" },
                                  [_vm._v(_vm._s(logItem.userRemark))]
                                ),
                              ])
                            : _vm._e(),
                          logItem.userRemarkFiles &&
                          logItem.userRemarkFiles.length
                            ? _c("div", { staticClass: "log-item-row" }, [
                                _c(
                                  "div",
                                  { staticClass: "log-item-row-label" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("lang_flow_log_appendix")) +
                                        "："
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "log-item-row-value" },
                                  [
                                    _c("ele-upload", {
                                      staticClass: "upload-demo",
                                      staticStyle: { width: "100%" },
                                      attrs: { disabled: "" },
                                      model: {
                                        value: logItem.userRemarkFiles,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            logItem,
                                            "userRemarkFiles",
                                            $$v
                                          )
                                        },
                                        expression: "logItem.userRemarkFiles",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _c("div", { staticClass: "log-item-row" }, [
                            _c("div", { staticClass: "log-item-row-label" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("lang_flow_log_processing_date")
                                ) + "："
                              ),
                            ]),
                            _c("div", { staticClass: "log-item-row-value" }, [
                              _vm._v(
                                _vm._s(_vm.getDatetime(logItem.completedDate))
                              ),
                            ]),
                          ]),
                        ]
                      : [
                          _c("div", { staticClass: "log-item-row" }, [
                            _c("div", { staticClass: "log-item-row-label" }, [
                              _vm._v(
                                _vm._s(_vm.$t("lang_flow_log_assignee")) + "："
                              ),
                            ]),
                            _c("div", { staticClass: "log-item-row-value" }, [
                              _vm._v(_vm._s(logItem.performersNames.join(","))),
                            ]),
                          ]),
                          _c("div", { staticClass: "log-item-row" }, [
                            _c("div", { staticClass: "log-item-row-label" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("lang_flow_log_acceptance_result")
                                ) + "："
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "log-item-row-value-type",
                                style: {
                                  backgroundColor: nodeItem.color,
                                  color: "#fff",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.getSubmitTypeName(logItem)) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "log-item-row" }, [
                            _c("div", { staticClass: "log-item-row-label" }, [
                              _vm._v(
                                _vm._s(_vm.$t("lang_flow_log_arrival_date")) +
                                  "："
                              ),
                            ]),
                            _c("div", { staticClass: "log-item-row-value" }, [
                              _vm._v(
                                _vm._s(_vm.getDatetime(logItem.createDate))
                              ),
                            ]),
                          ]),
                        ],
                  ],
                  2
                )
              }),
            ],
            2
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }