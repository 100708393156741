















































































import DateUtil from 'global-ui/packages/utils/DateUtil';
import { FlowOperationList } from '@/models/flow/FlowConst';
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import EleUpload from '@/components/form-design/elements/ele-upload.vue';
import LoginApi from '@/api/system/LoginApi';
import StorageUtil from 'global-ui/packages/utils/StorageUtil';
@Component({
  name: 'FlowLogs',
  components: { EleUpload }
})
export default class ProcessSubmitWords extends Vue {
  // 接受父组件的节点实例ID
  @Prop({
    required: false,
    default: () => []
  })
  data!: any[];
  logs: any[] = [];
  operationList: Record<string, string>[] = FlowOperationList;
  defaultNodeColor: string = '#409EFF';
  excutedNodeColor: string = '#33aaaa';
  arrivingNodeColor: string = '#dd8877';

  get _activedLogNode() {
    return this.$store.getters.activedLogNode;
  }

  get _currentTask() {
    return this.$store.getters.currentTask;
  }

  get _processEnd() {
    if (this.$store.getters.processInstance) {
      return this.$store.getters.processInstance.end;
    }
    return false;
  }

  created() {
    this.initData();
  }

  initData() {
    this.logs = this.buildLogs();
  }

  buildLogs() {
    if (this.data && this.data.length) {
      return this.data.map(logItem => {
        if (this._activedLogNode && this._activedLogNode.id == logItem.nodeId) {
          if (this._activedLogNode.properties.isExecuted) {
            logItem.color = this.excutedNodeColor;
            logItem.fontWeight = 700;
            logItem.fontColor = this.excutedNodeColor;
          } else if (this._activedLogNode.properties.isArriving) {
            logItem.color = this.arrivingNodeColor;
            logItem.fontWeight = 700;
            logItem.fontColor = this.arrivingNodeColor;
          }
          (document.getElementById(logItem.nodeId) as any).scrollIntoView({ behavior: 'smooth' });
        } else {
          logItem.color = this.defaultNodeColor;
          logItem.fontWeight = 500;
          logItem.fontColor = '#303133';
        }
        return logItem;
      });
    }
    return [];
  }

  /**
   * 格式化日期
   */
  getDatetime(date: Date) {
    if (date) {
      return DateUtil.format(new Date(date), 'yyyy-MM-dd hh:mm:ss');
    }
    return '';
  }

  /**
   * 格式化提交类型
   */
  getSubmitTypeName(logItem: any): string {
    let result: any = '';
    this.operationList.forEach((item: any) => {
      if (item.id == logItem.submitType) {
        result = this.$t(item.name);
      }
    });
    return result;
  }

  openHistoryTask(nodeItem): void {
    this.$router.push({
      path: '/flow/done',
      query: { id: nodeItem.taskHistories[0].id }
    });
  }

  toMessageWindow() {
    let token = StorageUtil.getSessionStorage('token');
    LoginApi.getCurrentUser().then((res: Record<any, any>) => {
      if (res.code == '1' && res.data) {
        let userAccount = res.data.account;
        window.location.href =
          'https://10.58.2.32/im/web?loginId=' + userAccount + '&username=' + userAccount + '&appId=caba244d-56b6-49a1-9da3-834806a927dd';
      } else {
        this.$message.error(res.message);
      }
    });
  }

  @Watch('data')
  dataWatcher(newVal, oldVal) {
    this.logs = this.buildLogs();
  }

  @Watch('_activedLogNode')
  activedLogNodeWatcher(newVal, oldVal) {
    if (newVal.id != oldVal.id) {
      this.logs = this.buildLogs();
    }
  }
}
