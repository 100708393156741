







































































































































import { Component, Prop, ProvideReactive, Vue, Watch } from 'vue-property-decorator';
import FormViewer from '@/components/form-design/viewer/form-viewer.vue';
import FlowGraph from '@/components/flow-design/flow-graph.vue';
import FlowLogs from './flow-logs.vue';
import UserTransfer from '@/components/user-transfer/index.vue';
import ReportList from '@/components/report-design/report-list.vue';
import { NodeConfig } from '@logicflow/core';
import { GraphNodeData } from '@/models/flow/GraphNodeModel';
import { assignTask, backTask, ccTask, delegateTask, jumpTask2, submitTask } from '@/api/flow-design/FlowApi';

@Component({
  name: 'FlowViewer',
  components: { FormViewer, FlowGraph, FlowLogs, UserTransfer, ReportList }
})
export default class FlowStart extends Vue {
  @Prop({ default: '' }) operation!: string; // 操作类型  process_new task_startup task_runtime task_completed task_end
  @Prop({ default: '' }) taskDataId!: string; // 任务id
  @Prop({ default: true }) readonly!: boolean; // 任务id

  @ProvideReactive() flowId: any = null;
  @ProvideReactive() formId: any = null;
  @ProvideReactive() formDataId: any = null;

  activeName: string = 'form'; // tab按钮名称

  taskName: string = '';
  currTabName: string = 'form'; // 当前显示内容（form: 表单； flow: 流程图）
  currentGraphNode: NodeConfig = GraphNodeData;

  currentFormData: any = {};

  currentOperationBtn: any = {}; // 当前点击的按钮(流程事件类型-提交、退回等)
  currentLayoutBtn: any = {}; // 当前点击的按钮（页面切换类型的-弹出框、切换页面）
  curOptBtns: any[] = []; // 页面切换后要的按钮
  stepBtnsStack: any[] = []; // 将每一步的按钮放入堆栈中，方便切换

  // 加签
  assignDialogFlag: boolean = false;
  assignModel: any = {
    identityScope: {
      userIds: [],
      groupIds: []
    },
    submitWords: ''
  };

  // 抄送
  ccDialogFlag: boolean = false;
  ccModel: any = {
    identityScope: {
      userIds: [],
      groupIds: []
    },
    ccWords: ''
  };

  // 转办
  dgDialogFlag: boolean = false;
  dgModel: any = {
    identityScope: {
      userIds: [],
      groupIds: []
    },
    ccWords: ''
  };

  printDialogFlag: boolean = false; // 是否显示 选择打印模板 的弹出框

  borderColor: string = 'transparent'; // 边框颜色
  fontColor: string = ''; // 字体颜色

  freeReturnNodeId: string = ''; // 自由退回节点id
  freeReturnDialogFlag: boolean = false; // 是否显示 选择退回节点 的弹出框;
  showProcess: boolean = false; //是否显示抄送按钮
  get _flowConfig() {
    return this.$store.getters.flowConfig;
  }

  get _description() {
    if (this._flowConfig && this._flowConfig.description) {
      this._flowConfig.displayDescription;
    }
    return '';
  }

  //当前节点
  get _activedNode() {
    return this.$store.getters.activedNode;
  }

  //当前节点
  get _currentTask() {
    return this.$store.getters.currentTask;
  }

  // 流程标题
  get _taskName() {
    if (this._flowConfig && this._flowConfig.id && this._activedNode && this._activedNode.id) {
      return (
        (this._flowConfig.displayName || this._flowConfig.name) +
        '-' +
        (this._activedNode.text.displayName || this._activedNode.text.displayValue)
      );
    }
    return '';
  }

  // 流程日志
  get _logs() {
    return this.$store.getters.processLogs;
  }

  // 流程日志
  get _taskHistories() {
    return this.$store.getters.taskHistories;
  }

  get _formConfigs() {
    return this.$store.getters.formConfigs;
  }

  get _pageLoading() {
    if (this._taskName && this.formId) {
      return true;
    }
    return false;
  }

  created() {
    let formConfig = this._formConfigs[this.formId];
    if (formConfig && formConfig.container.properties) {
      if (formConfig.container.properties.hasBorder) {
        this.borderColor = formConfig.container.properties.borderColor;
      }
      this.fontColor = formConfig.container.properties.fontColor;
    } else {
      this.borderColor = 'transparent';
    }
  }

  handleClick() {
    this.changeTab(this.activeName);
  }

  /**
   * 初始化页面数据
   */
  initData() {
    this.curOptBtns = this.filterBtns(this._activedNode.properties.nodeActions);
    // 抄送按钮
    if (this.curOptBtns && this.curOptBtns.length > 0) {
      let processData = this.curOptBtns.find(item => item.operation == 'cc');
      this.showProcess = processData ? false : true;
    } else {
      this.showProcess = true;
    }
    this.stepBtnsStack.push(this.curOptBtns);
  }

  filterBtns(btns) {
    let result: any[] = [];
    btns.forEach(btnItem => {
      if (btnItem.taskPages.includes(this.operation)) {
        if (btnItem.children && btnItem.children.length) {
          btnItem.children = this.filterBtns(btnItem.children);
        }
        result.push(btnItem);
      }
      // if (btnItem.actionType == 'process') {
      //   if (btnItem.taskPages.includes(this.operation)) {
      //     result.push(btnItem);
      //   }
      // } else {
      //   if (btnItem.children && btnItem.children.length) {
      //     btnItem.children = this.filterBtns(btnItem.children);
      //   }
      //   result.push(btnItem);
      // }
    });
    return result;
  }

  /**
   * 构造所有按钮的map
   */
  buildOptBtnMap(btns) {
    let result = {};
    btns.forEach(btnItem => {
      result[btnItem.actionKey] = btnItem;
      if (btnItem.children && btnItem.children.length) {
        result = Object.assign(result, this.buildOptBtnMap(btnItem.children));
      }
    });
    return result;
  }

  // 切换显示内容
  changeTab(tabName) {
    this.currTabName = tabName;
    if (tabName == 'flow') {
      (this.$refs.flowGrapgRef as any).focusOnCenter();
    }
  }
  /**
   * 点击按钮时
   */
  handleOperation(btnItem) {
    if (btnItem.operation) {
      this.currentOperationBtn = { ...btnItem };
      switch (btnItem.operation) {
        case 'submit':
          this.submitFlow();
          break;
        case 'disagree':
          this.rejectFlow();
          break;
        case 'revoke':
          this.revokeFlow();
          break;
        case 'assign':
          this.assignFlow();
          break;
        case 'delegate':
          this.delegateFlow();
          break;
        case 'cc':
          this.ccFlow();
          break;
        case 'print':
          this.printFlow();
          break;
        default:
          this.saveFlow();
      }
    } else {
      this.currentLayoutBtn = { ...btnItem };
      this.curOptBtns = this.getOptBtns(btnItem);
    }
  }

  /**
   * 获取页面切换后的按钮
   */
  getOptBtns(btnItem) {
    if (btnItem.actionType == 'form_flush') {
      this.stepBtnsStack.push(btnItem.children);
    } else if (btnItem.actionType == 'form_back') {
      this.stepBtnsStack.pop();
    } else if (btnItem.actionType == 'form_dialog') {
    }
    if (this.stepBtnsStack && this.stepBtnsStack.length) {
      return this.stepBtnsStack[this.stepBtnsStack.length - 1];
    } else {
      return [];
    }
  }

  // 提交
  submitFlow() {
    // 获取表单参数
    this.getFormDataForSave().then(res => {
      this.$confirm(this.$t('lang_submit_task') as string, this.$t('lang_tips') as string, {
        confirmButtonText: this.$t('lang_determine_') as string,
        cancelButtonText: this.$t('lang_cancel_') as string,
        type: 'warning'
      }).then(() => {
        let params: any = {
          id: this._currentTask.id,
          formDataInstance: {
            formId: this._flowConfig.customBpmModel.config.formId,
            id: this._currentTask.dataInstanceId,
            formModelDataList: res.formModelDataList
          },
          arguments: this.buildBtnParams(this.currentOperationBtn)
        };
        this.buildGraphParams(params);
        submitTask(params).then((res: any) => {
          if (res.code == '1') {
            this.$message.success(res.message);
            this.closeWindow_old();
          } else {
            this.$message.error(res.message);
          }
        });
      });
    });
  }
  // 退回
  rejectFlow() {
    // 获取表单参数
    this.getFormDataForSave().then(res => {
      let freeReturn = false;
      if (this._activedNode.properties && this._activedNode.properties.returnSetting) {
        this.currentFormData = res;
        freeReturn = this._activedNode.properties.returnSetting.freeReturn;
        if (freeReturn) {
          this.freeReturnNodeId = '';
          this.freeReturnDialogFlag = true;
        } else {
          this.$confirm(this.$t('lang_return_task') as string, this.$t('lang_tips') as string, {
            confirmButtonText: this.$t('lang_determine_') as string,
            cancelButtonText: this.$t('lang_cancel_') as string,
            type: 'warning'
          }).then(() => {
            let targetNodeId = '';
            if (this._activedNode.properties && this._activedNode.properties.returnSetting) {
              targetNodeId = this._activedNode.properties.returnSetting.returnNodeId;
            }
            if (targetNodeId) {
              this.handleReject(targetNodeId);
            } else {
              this.$message.error(this.$t('lang_config_select_a_return_node') as any);
            }
          });
        }
      }
    });
  }

  // 退回，需要表单校验
  handleReject(targetNodeId) {
    // 获取表单参数
    if (!targetNodeId) {
      if (!this.freeReturnNodeId) {
        this.$message.error(this.$t('lang_please_select_a_return_node') as any);
        return;
      }
      targetNodeId = this.freeReturnNodeId;
    }
    let params: any = {
      id: this._currentTask.id,
      targetNodeId: targetNodeId,
      submitType: 5,

      formDataInstance: {
        formId: this._flowConfig.customBpmModel.config.formId,
        id: this._currentTask.dataInstanceId,
        formModelDataList: this.currentFormData.formModelDataList
      },
      arguments: this.buildBtnParams(this.currentOperationBtn)
    };
    this.buildGraphParams(params);
    backTask(params).then((res: any) => {
      if (res.code == '1') {
        this.$message.success(res.message);
        this.closeWindow_old();
      } else {
        this.$message.error(res.message);
      }
    });
  }

  // 撤回，不需要表单校验
  revokeFlow() {
    // 获取表单参数
    this.$confirm(this.$t('lang_withdraw_task') as string, this.$t('lang_tips') as string, {
      confirmButtonText: this.$t('lang_determine_') as string,
      cancelButtonText: this.$t('lang_cancel_') as string,
      type: 'warning'
    }).then(() => {
      let params: any = {
        processId: this._flowConfig.id,
        runtimeId: this._currentTask.runtimeId,
        targetNodeId: this._activedNode.id,
        submitType: 6,
        arguments: this.buildBtnParams(this.currentOperationBtn)
      };
      jumpTask2(params).then((res: any) => {
        if (res.code == '1') {
          this.$message.success(res.message);
          this.closeWindow_old();
        } else {
          this.$message.error(res.message);
        }
      });
    });
  }

  // 打开加签窗口
  assignFlow() {
    this.getFormDataForSave().then(res => {
      this.currentFormData = res;
      this.assignModel = {
        identityScope: {
          userIds: [],
          groupIds: []
        },
        submitWords: ''
      };
      this.assignDialogFlag = true;
    });
  }
  // 提交加签，需要表单校验
  handleAssign() {
    if (
      (!this.assignModel.identityScope.userIds || !this.assignModel.identityScope.userIds.length) &&
      (!this.assignModel.identityScope.groupIds || !this.assignModel.identityScope.groupIds.length)
    ) {
      this.$message.error(this.$t('lang_please_select_a_person') as any);
      return;
    }
    this.$confirm(this.$t('lang_assign_task') as string, this.$t('lang_tips') as string, {
      confirmButtonText: this.$t('lang_determine_') as string,
      cancelButtonText: this.$t('lang_cancel_') as string,
      type: 'warning'
    }).then(() => {
      let params: any = {
        ...this.assignModel,
        id: this._currentTask.id,
        formDataInstance: {
          formId: this._flowConfig.customBpmModel.config.formId,
          id: this._currentTask.dataInstanceId,
          formModelDataList: this.currentFormData.formModelDataList
        },
        arguments: this.buildBtnParams(this.currentOperationBtn)
      };
      assignTask(params).then((res: any) => {
        if (res.code == '1') {
          this.$message.success(res.message);
          this.assignDialogFlag = false;
          this.closeWindow_old();
        } else {
          this.$message.error(res.message);
        }
      });
    });
  }

  // 打开抄送窗口
  ccFlow() {
    this.ccModel = {
      identityScope: {
        userIds: [],
        groupIds: []
      },
      ccWords: ''
    };
    this.ccDialogFlag = true;
  }
  // 提交抄送，不需要表单校验
  handleCc() {
    if (
      (!this.ccModel.identityScope.userIds || !this.ccModel.identityScope.userIds.length) &&
      (!this.ccModel.identityScope.groupIds || !this.ccModel.identityScope.groupIds.length)
    ) {
      this.$message.error(this.$t('lang_please_select_a_person') as any);
      return;
    }
    this.$confirm(this.$t('lang_cc_task') as string, this.$t('lang_tips') as string, {
      confirmButtonText: this.$t('lang_determine_') as string,
      cancelButtonText: this.$t('lang_cancel_') as string,
      type: 'warning'
    }).then(() => {
      let params: any = {
        nodeInstanceId: this.taskDataId,
        ...this.ccModel,
        arguments: this.buildBtnParams(this.currentOperationBtn)
      };
      ccTask(params).then((res: any) => {
        if (res.code == '1') {
          this.$message.success(res.message);
          this.ccDialogFlag = false;
        } else {
          this.$message.error(res.message);
        }
      });
    });
  }

  // 打开转办窗口
  delegateFlow() {
    this.dgModel = {
      identityScope: {
        userIds: [],
        groupIds: []
      },
      delegateWords: ''
    };
    this.dgDialogFlag = true;
  }

  // 提交转办，不需要表单校验
  handleDelegate() {
    if (
      (!this.dgModel.identityScope.userIds || !this.dgModel.identityScope.userIds.length) &&
      (!this.dgModel.identityScope.groupIds || !this.dgModel.identityScope.groupIds.length)
    ) {
      return;
    }
    this.$confirm(this.$t('lang_transfer_task') as string, this.$t('lang_tips') as string, {
      confirmButtonText: this.$t('lang_determine_') as string,
      cancelButtonText: this.$t('lang_cancel_') as string,
      type: 'warning'
    }).then(() => {
      let params: any = {
        nodeInstanceId: this.taskDataId,
        ...this.dgModel,
        arguments: this.buildBtnParams(this.currentOperationBtn)
      };
      delegateTask(params).then((res: any) => {
        if (res.code == '1') {
          this.$message.success(res.message);
          this.closeWindow_old();
          this.dgDialogFlag = false;
        } else {
          this.$message.error(res.message);
        }
      });
    });
  }

  // 暂存，不需要表单校验
  saveFlow() {
    this.getFormDataForSave(false).then(res => {
      this.$confirm(this.$t('lang_draft') as string, this.$t('lang_tips') as string, {
        confirmButtonText: this.$t('lang_determine_') as string,
        cancelButtonText: this.$t('lang_cancel_') as string,
        type: 'warning'
      }).then(() => {
        let params: any = {
          startWait: true,
          id: this._currentTask.id,
          formDataInstance: {
            formId: this._flowConfig.customBpmModel.config.formId,
            id: this._currentTask.dataInstanceId,
            formModelDataList: res.formModelDataList
          },
          arguments: this.buildBtnParams(this.currentOperationBtn)
        };
        this.buildGraphParams(params);
        submitTask(params).then((res: any) => {
          if (res.code == '1') {
            this.$message.success(res.message);
            // this.closeWindow();
          } else {
            this.$message.error(res.message);
          }
        });
      });
    });
  }

  // 获取表单数据
  getFormDataForSave(validate: boolean = true) {
    return (this.$refs.formViewerRef as any).getFormDataForSave(validate);
  }

  // 判断流程图是否有变化，有的话 将最新的流程图配置传给后台
  buildGraphParams(params) {
    let newGraphData = (this.$refs.flowGrapgRef as any).getGraphData();
    let oldGraphData = this._flowConfig.customBpmModel.graph;
    // 处理翻译
    if (oldGraphData.nodes) {
      oldGraphData.nodes.forEach(item => {
        if (item.text.displayValue) {
          delete item.text.displayValue;
        }
      });
    }
    if (!this.isEqual(newGraphData, oldGraphData)) {
      let runtimeCustomModel = { config: this._flowConfig.customBpmModel.config, graph: newGraphData };
      params.runtimeCustomModel = runtimeCustomModel;
    }
  }

  //判断两个对象是否相等
  isEqual(objA, objB) {
    //相等
    if (objA === objB) return objA !== 0 || 1 / objA === 1 / objB;
    //空判断
    if (objA == null || objB == null) return objA === objB;
    //类型判断
    if (Object.prototype.toString.call(objA) !== Object.prototype.toString.call(objB)) return false;

    switch (Object.prototype.toString.call(objA)) {
      case '[object RegExp]':
      case '[object String]':
        //字符串转换比较
        return '' + objA === '' + objB;
      case '[object Number]':
        //数字转换比较,判断是否为NaN
        if (+objA !== +objA) {
          return +objB !== +objB;
        }

        return +objA === 0 ? 1 / +objA === 1 / objB : +objA === +objB;
      case '[object Date]':
      case '[object Boolean]':
        return +objA === +objB;
      case '[object Array]':
        //判断数组
        for (let i = 0; i < objA.length; i++) {
          if (!this.isEqual(objA[i], objB[i])) return false;
        }
        return true;
      case '[object Object]':
        //判断对象
        let keys = Object.keys(objA);
        for (let i = 0; i < keys.length; i++) {
          if (!this.isEqual(objA[keys[i]], objB[keys[i]])) return false;
        }

        keys = Object.keys(objB);
        for (let i = 0; i < keys.length; i++) {
          if (!this.isEqual(objA[keys[i]], objB[keys[i]])) return false;
        }

        return true;
      default:
        return false;
    }
  }

  // 构建按钮参数
  buildBtnParams(btnItem) {
    let result = {};
    if (btnItem.paramValueSettings) {
      btnItem.paramValueSettings.forEach(item => {
        result[item.paramOptionName] = item.value;
      });
    }
    return result;
  }

  /**
   * 打印
   */
  printFlow() {
    this.printDialogFlag = true;
  }

  /**
   * 路由跳转回首页
   */
  closeWindow() {
    this.$router.push({ path: '/home' });
  }

  /**
   * 关闭窗口
   */
  closeWindow_old() {
    if (navigator.userAgent.indexOf('MSIE') > 0) {
      // IE
      if (navigator.userAgent.indexOf('MSIE 6.0') > 0) {
        // IE6
        window.opener = null;
        window.close();
      } else {
        // IE6+
        window.open('', '_top');
        window.top!.close();
      }
    } else if (navigator.userAgent.indexOf('Firefox') > 0 || navigator.userAgent.indexOf('Presto') > 0) {
      // FF和Opera
      window.location.href = 'about:blank';
      window.close(); // 火狐默认状态非window.open的页面window.close是无效的
    } else {
      window.opener = null;
      window.open('', '_self', '');
      window.close();
    }
  }

  @Watch('_flowConfig')
  flowConfigWatcher(val) {
    this.flowId = val.id;
  }

  @Watch('_currentTask')
  currentTaskWatcher(val) {
    this.formDataId = val.dataInstanceId;
  }

  @Watch('_formConfigs')
  formConfigsWatcher() {
    if (this._flowConfig && this._flowConfig.id && this._flowConfig.customBpmModel.config) {
      this.formId = this._flowConfig.customBpmModel.config.formId;
    } else {
      this.formId = null;
    }
  }

  @Watch('_pageLoading')
  pageLoadingWatcher() {
    this.initData();
  }
}
